import { murmurhash3 } from './utils';

export function getCanvas2dHash(): number {
  let canvas: HTMLCanvasElement;
  let ctx: CanvasRenderingContext2D;
  try {
    canvas = document.createElement('canvas');
    ctx = canvas.getContext('2d');
  } catch (e) {}

  if (!ctx) {
    return null;
  }

  ctx.fillStyle = 'red';
  ctx.fillRect(30, 10, 200, 100);

  ctx.strokeStyle = '#1a3bc1';
  ctx.lineWidth = 6;
  ctx.lineCap = 'round';
  ctx.arc(50, 50, 20, 0, Math.PI, false);
  ctx.stroke();

  ctx.fillStyle = '#42e1a2';

  ctx.font = "15.4px 'Arial'";
  ctx.textBaseline = 'alphabetic';
  ctx.fillText('PR flacks quiz gym: TV DJ box when? \u2620', 15, 60);
  ctx.shadowOffsetX = 1;
  ctx.shadowOffsetY = 2;
  ctx.shadowColor = 'white';
  ctx.fillStyle = 'rgba(0, 0, 200, 0.5)';
  ctx.font = "60px 'Not a real font'";
  ctx.fillText('No\u9A97', 40, 80);

  return murmurhash3(canvas.toDataURL(), 0);
}
