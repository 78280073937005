import { getCanvas2dHash } from './canvas';
import { Codecs, getCodecs } from './codecs';
import { SystemColors, getSystemColors } from './colors';
import { getDOMRect } from './domrect';
import { getFonts } from './fonts';
import { getMath } from './math';
import { DevicePlugin, getPlugins } from './plugins';
import {
  ObjectProperties,
  getNavigatorProperties,
  getScreenProperties,
} from './properties';
import { SpeechSynthesisProperties, getSpeechSynthesis } from './speech';
import { StoredIds } from './storedids';
import { inThirdPartyIframe, mmapiws } from './utils';
import { Webgl, getWebgl } from './webgl';

declare global {
  // eslint-disable-next-line
  var maxmind_user_id: number;
  // eslint-disable-next-line
  var survey_height_follow: number;
}

interface Battery {
  charging: boolean;
  chargingTime: number;
  dischargingTime: null;
  level: number;
}

interface Canvas {
  '2dHash': number;
  webgl: Webgl;
}

interface Fonts {
  css: string[];
}

export interface Device {
  accountId: number;
  applePay: ObjectProperties;
  canvas: Canvas;
  codecs: Codecs;
  battery?: Battery;
  deviceTime: number;
  documentUrl: string;
  fonts: Fonts;
  hasGPC: boolean;
  heapSizeLimit: number;
  math: object;
  navigator: ObjectProperties;
  plugins: DevicePlugin[];
  requestDuration?: number;
  screen: ObjectProperties;
  speechSynthesis: SpeechSynthesisProperties;
  storedIds?: StoredIds;
  systemColors: SystemColors;
  timezoneOffset: number;
  touchInput: boolean;

  // experimental
  rects: number;
}

export async function getDevice(): Promise<Device> {
  const date = new Date();

  // This can fail on ancient versions of Firefox (e.g., 3.6)
  let systemColors: SystemColors;
  try {
    systemColors = getSystemColors();
  } catch (e) {}

  const accountId = getAccountId();
  const device: Device = {
    accountId: accountId,
    applePay: getApplePay(),
    canvas: {
      '2dHash': getCanvas2dHash(),
      webgl: getWebgl(),
    },
    codecs: getCodecs(),
    deviceTime: date.getTime() / 1000,
    documentUrl: document.URL,
    fonts: {
      css: getFonts(),
    },
    hasGPC: getGPC(),
    heapSizeLimit: getHeapSizeLimit(),
    math: getMath(),
    navigator: await getNavigatorProperties(),
    plugins: getPlugins(),
    screen: getScreenProperties(),
    speechSynthesis: await getSpeechSynthesis(),
    systemColors: systemColors,
    timezoneOffset: date.getTimezoneOffset(),
    touchInput: getSupportsTouchInput(),
    rects: await getDOMRect(),
  };

  if ((navigator as any).getBattery) {
    const batteryData = await (navigator as any).getBattery();
    device.battery = {
      charging: batteryData.charging,
      chargingTime: batteryData.chargingTime,
      dischargingTime: batteryData.dischargingTime,
      level: batteryData.level,
    };
  }

  return device;
}

function getApplePay(): ObjectProperties {
  if (inThirdPartyIframe()) {
    return null;
  }
  const ap = (window as any).ApplePaySession;
  if (!ap) {
    return null;
  }

  return {
    canMakePayments: ap.canMakePayments(),
  };
}

function getGPC(): boolean {
  const gpc = (navigator as any).globalPrivacyControl;

  // While the spec defines this as a boolean, there seems to be some
  // confusion about it (MDN has it as an integer or maybe a string).
  return !!gpc;
}

function getHeapSizeLimit(): number {
  const memory = (performance as any).memory;
  return memory ? memory.jsHeapSizeLimit : 0;
}

// Based on https://stackoverflow.com/a/4819886/211029 UPDATE 2018, which
// is based on Modernizr
function getSupportsTouchInput(): boolean {
  try {
    if (navigator.maxTouchPoints > 0 || 'ontouchstart' in window) {
      return true;
    }

    const query =
      '(-webkit-touch-enabled),(-moz-touch-enabled),(-o-touch-enabled),(-ms-touch-enabled)';
    // Not supported until IE10. Raises an error.
    return window.matchMedia(query).matches;
  } catch (e) {
    return false;
  }
}

function getAccountId(): number {
  if (typeof window.survey_height_follow !== 'undefined') {
    return window.survey_height_follow;
  }
  if (typeof window.maxmind_user_id !== 'undefined') {
    return window.maxmind_user_id;
  }
  return mmapiws.accountId;
}
