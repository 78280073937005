/* LICENSING
 *
 * A copy of the attribution of all licensed code used in device.js must be
 * kept directly before the related function or code.  If the minification
 * process is stripping the attribution comment from minified version of
 * device.js, you can alternately place a copy right after this comment.
 *
 * For a comment directly before a function, use the `function` keyword, and
 * not a function expression - otherwise `uglifyjs` will discard the comment.
 * i.e:
 *
 * // this comment won't be in minified js
 * var myfunc = function(a,b) { return a + b };
 *
 * // properly attributed function in minified js
 * function myfunc(a,b) { return a + b }
 *
 * For a license comment to be included in the minified and deployed device.js,
 * it must include one of the following strings, with ":" characters removed.
 * Or, the comment can begin with a "!" character.
 *
 * @:preserve
 * @:license
 * @:cc_on
 */
// We do not "use strict" as it can result in failures when properties get set
// to readonly, e.g., in Safari's private browsing mode. In most cases, we do
// not want these failures to set an event handler, etc., to stop the
// execution of the script.
import { Device, getDevice } from './device';
import { getStoredIds, setStoredIds } from './storedids';
import { timer } from './utils';

async function runDevice(): Promise<void> {
  const device = await getDevice();
  await sendToServer(device, 6);
}

async function ready(): Promise<void> {
  if (typeof document.body !== 'undefined' && document.body) {
    await runDevice();
  } else {
    await timer(500);
    await ready();
  }
}

async function handleResponse(
  body: string,
  version: number,
  device: Device,
): Promise<void> {
  const responseValues = body.split(/;/);
  const domain = responseValues[0];
  const id = responseValues[1];
  const serverIpVersion = parseInt(responseValues[2], 10);

  if (typeof id !== 'undefined') {
    await setStoredIds(domain, id);
  }

  if (version === 6 && serverIpVersion === 6) {
    await sendToServer(device, 4);
  }
}

function getRequestDuration(): number {
  if (!window.performance) {
    return null;
  }

  const resources = performance.getEntriesByType('resource');
  if (resources === undefined) {
    return null;
  }

  const uri = createUri(6); // The first request.

  for (let i = 0; i < resources.length; i++) {
    if (resources[i].name !== uri) {
      continue;
    }

    // We could consider using a different phase for the start time instead
    // of startTime, such as domainLookupStart. Although this is probably
    // sufficient. We use responseEnd because we're looking for the round
    // trip time.
    return (
      (resources[i] as PerformanceResourceTiming).responseEnd -
      resources[i].startTime
    );
  }

  return null;
}

async function sendToServer(device: Device, version: number): Promise<void> {
  const uri = createUri(version);

  // We set this here so that it is updated for subsequent requests
  device.storedIds = await getStoredIds();

  // Pass along how long the first request took. We think this could be
  // useful for proxy detection. Currently this only works in the cases where
  // we already make 2 requests. If it proves useful, we may want to always
  // send 2+ requests, although that will make device take more time and
  // resources.
  if (version === 4) {
    const requestDuration = getRequestDuration();
    if (requestDuration !== null) {
      device.requestDuration = requestDuration;
    }
  }

  const response = await fetch(uri, {
    method: 'POST',
    body: jsonStringify(device),
  });

  if (response.status === 200) {
    await handleResponse(await response.text(), version, device);
  }
}

// We can use JSON.stringify, but we have to protect against toJSON
// methods on Object and Array (e.g., from certain versions of the
// Prototype framework).
function jsonStringify(obj: object): string {
  const hasArrayToJSON = 'toJSON' in Array.prototype;
  const hasObjectToJSON = 'toJSON' in Object.prototype;
  const arrayToJSON = (Array.prototype as any).toJSON;
  const objectToJSON = (Object.prototype as any).toJSON;
  try {
    delete (Array.prototype as any).toJSON;
    delete (Object.prototype as any).toJSON;
    return JSON.stringify(obj);
  } finally {
    /* eslint no-extend-native: 0 */
    // Only restore if they were set initially.
    if (hasArrayToJSON) {
      (Array.prototype as any).toJSON = arrayToJSON;
    }
    if (hasObjectToJSON) {
      (Object.prototype as any).toJSON = objectToJSON;
    }
  }
}

function createUri(version: number): string {
  let targetHostname: string;
  const hostname = window.location.hostname;
  const revHostnameSplit = hostname.split('.').reverse();
  if (
    revHostnameSplit[1] === 'maxmind' &&
    (revHostnameSplit[0] === 'com' || revHostnameSplit[0] === 'dev') &&
    hostname !== 'www.maxmind.com'
  ) {
    targetHostname = hostname;
  }
  return (
    'https://' +
    (targetHostname || 'd-ipv' + version + '.mmapiws.com') +
    '/ant_squire'
  );
}

ready().catch((e) => console.error(e));
