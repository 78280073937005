import { murmurhash3 } from './utils';

const styles: Partial<CSSStyleDeclaration>[] = [
  {
    width: '150px',
    height: '100px',
    border: '2px solid rgba(0,150,255,0.91)',
    color: 'rgba(255,255,255,1)',
    background: 'rgb(255,255,255)',
    position: 'absolute',
    transform:
      'skewY(29.1755628deg) rotate3d(11.000000007, 90, 0.100000000000009, 60000000000033.0000000006deg)',
  },
  {
    width: '150px',
    height: '100px',
    border: 'solid 2.89px',
    borderColor: '#3A0CA3',
    fontSize: '45px',
    marginTop: '50px',
    position: 'absolute',
    transform:
      'skewY(29.1755618deg) scale(3.0000000000333, -1.00002) matrix(33.0000000003, 1.000001, -2.0000003, 1.009, 33.00000003, -6.0000006)',
  },
  {
    width: '150px',
    height: '100px',
    border: '2px solid rgba(0,150,255,0.91)',
    borderColor: 'rgba(0,150,255,0.91)',
    padding: '15px',
    position: 'absolute',
    transform: 'perspective(1555px) translateZ(-25.0000001px)',
  },
  {
    width: '150px',
    height: '100px',
    border: '2px solid rgba(0,1,255,0.91)',
    top: '0.1rem',
    left: '0.1rem',
    marginTop: '--255.50005px',
    marginLeft: '0.500005rem',
    position: 'absolute',
  },
  {
    width: '150px',
    height: '100px',
    border: '2px solid rgba(118,165,175,1)',
    marginTop: '-333.552px',
    marginLeft: '13.90000099rem',
    padding: '4.4545px',
    left: '5px',
    top: '5px',
    position: 'absolute',
    transform:
      ' matrix(33.0000000003, 1.000001, -2.0000003, 1.009, 33.00000003, -6.0000006)',
  },
];
export async function getDOMRect(): Promise<number> {
  try {
    const iframe = document.createElement('iframe');
    iframe.style.visibility = 'hidden';
    document.body.appendChild(iframe);
    return new Promise<number>((resolve, reject) => {
      iframe.onload = function () {
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow?.document;
        if (!iframeDocument) {
          reject(new Error('Failed to create iframe document'));
          return;
        }
        styles.forEach((style, index) => {
          const rect = iframeDocument.createElement('div');
          rect.id = `rect-${index}`;
          Object.assign(rect.style, style);
          iframeDocument.body.appendChild(rect);
        });

        const getRect = (id: string) =>
          iframeDocument.getElementById(id)?.getClientRects()[0];

        const rects: DOMRect[] = styles.map((_, index) => {
          const rect = getRect(`rect-${index}`);
          if (!rect) {
            reject(new Error(`Failed to get rect for rect-${index}`));
          }
          return rect;
        });

        const rectHash = murmurhash3(JSON.stringify(rects), 0);
        document.body.removeChild(iframe);
        resolve(rectHash);
      };
      iframe.src = 'about:blank';
    });
  } catch (e) {
    return null;
  }
}
