export interface SystemColors {
  ActiveBorder?: string;
  ActiveCaption?: string;
  AppWorkspace?: string;
  Background?: string;
  ButtonFace?: string;
  ButtonHighlight?: string;
  ButtonShadow?: string;
  ButtonText?: string;
  CaptionText?: string;
  GrayText?: string;
  Highlight?: string;
  HighlightText?: string;
  InactiveBorder?: string;
  InactiveCaption?: string;
  InactiveCaptionText?: string;
  InfoBackground?: string;
  InfoText?: string;
  Menu?: string;
  MenuText?: string;
  Scrollbar?: string;
  ThreeDDarkShadow?: string;
  ThreeDFace?: string;
  ThreeDHighlight?: string;
  ThreeDLightShadow?: string;
  ThreeDShadow?: string;
  Window?: string;
  WindowFrame?: string;
  WindowText?: string;
}

export function getSystemColors(): SystemColors {
  const colors: SystemColors = {};
  let canvas: HTMLCanvasElement;
  let ctx: CanvasRenderingContext2D | null;
  try {
    canvas = document.createElement('canvas');
    ctx = canvas.getContext('2d');
  } catch (e) {}

  if (!ctx) {
    return colors;
  }

  const elements = [
    'ActiveBorder',
    'ActiveCaption',
    'AppWorkspace',
    'Background',
    'ButtonFace',
    'ButtonHighlight',
    'ButtonShadow',
    'ButtonText',
    'CaptionText',
    'GrayText',
    'Highlight',
    'HighlightText',
    'InactiveBorder',
    'InactiveCaption',
    'InactiveCaptionText',
    'InfoBackground',
    'InfoText',
    'Menu',
    'MenuText',
    'Scrollbar',
    'ThreeDDarkShadow',
    'ThreeDFace',
    'ThreeDHighlight',
    'ThreeDLightShadow',
    'ThreeDShadow',
    'Window',
    'WindowFrame',
    'WindowText',
  ];

  for (let i = 0; i < elements.length; i++) {
    ctx.fillStyle = elements[i];
    colors[elements[i]] = ctx.fillStyle;
  }

  return colors;
}
